<template>
  <div>
    <transition name="fade">
      <div>
        <div class="pc">
          <div class="card-wrapper" v-if="cards.length>0" :style="`grid-template-columns:repeat(${grid}, 1fr)`">
            <component :is="componentName" v-for="(card, idx) in cards" :key="'card-pc-'+idx" :card.sync="card"></component>
          </div>

          <template v-if="cardReqs.length>0">
            <div class="body3-medium sub2 title-stop">{{ menu.label }} 신청 내역</div>
            <div class="card-wrapper" :style="`grid-template-columns:repeat(${grid}, 1fr);${grid>1 ? 'margin-top:16px;' : ''}`">
              <component :is="componentName" v-for="(card, idx) in cardReqs" :key="'card-pc-stop-'+idx" :card.sync="card"></component>
            </div>
          </template>

          <template v-if="cardStops.length>0">
            <div class="body3-medium sub2 title-stop">이용 중지된 내역</div>
            <div class="card-wrapper" :style="`grid-template-columns:repeat(${grid}, 1fr)`">
              <component :is="componentName" v-for="(card, idx) in cardStops" :key="'card-pc-stop-'+idx" :card.sync="card"></component>
            </div>
          </template>

          <div v-if="cards.length === 0 && cardReqs.length === 0 && cardStops.length === 0"
               class="empty-view">{{ menu.label }} 구매 내역이 없습니다</div>
        </div>

        <div class="tablet">
          <div class="card-wrapper" :style="`grid-template-columns:repeat(${grid}, 1fr)`">
            <component :is="componentName" v-for="(card, idx) in cards" :key="'card-tablet-'+idx" :card.sync="card"></component>
          </div>
        </div>

        <div class="mobile">
          <div class="card-wrapper" v-if="cards.length>0">
            <div :class="{'margin-top-40': idx>0, 'margin-top-24': idx===0}" v-for="(card, idx) in cards" :key="'card-mobile-'+idx">
              <component :is="componentName" :card.sync="card"></component>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import CardOrderProduct from "./CardOrderProduct";
  import CardOrderService from "./CardOrderService";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";

  export default {
    name: "ListOrdered",
    mixins: [
      ServiceAdminAPIMixin
    ],
    components: {
      CardOrderProduct,
      CardOrderService,
    },
    props: {
      menu: {
        type: Object
      },
      shadow: {
        type: Boolean,
        default: false
      }
    },
    created() {
      /*if(this.menu.name.indexOf('서비스') > -1) {
        this.grid = 3;
      }*/
      this.getData();
    },
    data() {
      return {
        grid: 1,
        cards: [],
        cardReqs: [],
        cardStops: []
      }
    },
    watch: {
    },
    computed: {
      componentName() {
        //return this.menu.name.indexOf('서비스') > -1 ? 'CardOrderService' : 'CardOrderProduct';
        return 'CardOrderProduct';
      },
      appType() {
        return {
          '플랫폼 팩': 'package',
          '플러그인': 'plugin',
          '추가서비스': 'service'
        }[this.menu.name];
      }
    },
    methods: {
      getData() {
        this.request.serviceAdmin.get(`launchpack/v1/service/${this.userService.id}/app_order?app_type=${this.appType}`).then(res=>{
          res.data.forEach(i => {
            i.product = i.products[0];
            delete i.products;
          });
          this.cardReqs = res.data.filter(i=>{ return [1,4,5,6].indexOf(i.status)>-1});
          this.cards = res.data.filter(i=>{ return i.status === 2});
          this.cardStops = res.data.filter(i=>{ return i.status === 3});

          let menu = this.menu;
          if(this.menu.name === '추가서비스') {
            menu.product_length = this.cardReqs.length;
          } else {
            menu.product_length = this.cards.length;
          }
          this.$emit('update:menu', menu);

        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-column-gap 16px
      grid-row-gap 16px

    .title-stop
      padding 28px 0 12px 0
      border-bottom 1px solid $main

  @media (max-width:1024px)
    .pc
      display none
    .tablet
      display block
    .mobile
      display none

  @media (max-width:640px)
    .pc
      display none
    .tablet
      display none
    .mobile
      display block

</style>
