<template>
  <div>
    <banner-market></banner-market>
    <!-- 메뉴 -->
    <div :style="fixedStyle">
      <div class="flex-center cat-wrapper" v-if="filtered.selectedMenu">
        <div v-for="menu in menus"
             :key="'menu-'+menu.id"
             class="cat-st subtitle5 unselect"
             :class="{'primary':filtered.selectedMenu.id===menu.id}"
             @click="clickMenu(menu)">
          {{ menu.label }}
          <div :class="{'cat-selected':filtered.selectedMenu.id===menu.id}"></div>
        </div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <!-- 전체가 아닐경우 -->
        <div v-if="filtered.selectedMenu && filtered.selectedMenu.label!=='전체'">
          <div class="h5 padding-top-60 padding-bottom-28">
            {{ filtered.selectedMenu.label==='전체'?'전체스킨':filtered.selectedMenu.label }}
          </div>
          <ListOrdered :key="'list-market-'+filtered.selectedMenu.id" :menu.sync="filtered.selectedMenu" :shadow="true"></ListOrdered>
        </div>
        <!-- 전체일 경우 -->
        <div v-else-if="menus.length>0">
          <template v-for="(item, item_idx) in menus.slice(1,menus.length)">
            <div class="product-item-wrapper" :key="`menu-${item_idx}`">
              <div class="flex-between padding-top-60 padding-bottom-28">
                <div class="unselect" @click="clickMenu(item)">
                  <span class="h5">{{ item.label }}</span>
                  <span class="h5 primary margin-left-4">{{ item.product_length }}</span>
                </div>
                <div class="body2-medium primary unselect" @click="clickMenu(item)">더보기</div>
              </div>
              <ListOrdered :menu.sync="item" :shadow="true"></ListOrdered>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import ListMixin from "../../mixins/ListMixin";
  import BannerMarket from "../component/BannerMarket";
  import ListOrdered from "../component/ListOrdered";
  export default {
    components: {
      ListOrdered,
      BannerMarket,
    },
    name: "OrderList",
    mixins: [
      ListMixin
    ],
    watch: {
      '$route': {
        handler(n) {
          this.vMarket = n.path.indexOf('/detail') === -1;
        },
        deep: true
      }
    },
    data() {
      return {
        vMarket: true,
        filtered: {
          selectedMenu: undefined
        },
        menus: [],
        recent:false,
        fields: 'id,name,simple_desc,img,price,partner.img,partner.name,category1,category2,preview_url,tags,params'
      }
    },
    created() {
      this.pageLoadType = 'infinite';
      this.init();
    },
    computed: {
      fixedStyle() {
        let deco = {
          borderBottom: '1px solid #e3e3e3'
        };
        if (this.fixedState) {
          deco = Object.assign(deco, {
            width: '100%',
            backgroundColor: '#fff',
            position: 'fixed',
            top: '70px',
            left: '0',
            zIndex: 9
          });
        }
        return deco;
      },
    },
    methods: {
      // 탭 선택
      clickMenu(menu) {
        this.setGa(`테마 카테고리(${menu.label})`, '클릭', menu.label);
        this.getMenu(menu);
        window.scrollTo(0, 0);
        document.getElementById('router').scrollTo(0,0);
      },
      getMenu(menu) {
        this.current_page = 1;
        this.filtered.page_num = 1;
        this.filtered.selectedMenu = menu;
      },
      init() {
        let url = 'public/category?parent_id=46'
        this.$axios.get(url).then(res => {
          if (res.status === 200) {
            let menus = [{
                desc: "",
                img: "",
                id: 0,
                label: "전체"
              }]
            ;
            this.menus = menus.concat(res.data).map(i => {
              i.product_length = 0;
              return i;
            });
            this.menus[0].label = '전체';

            if(this.$route.query.tab) {
              let menu = this.findItem(this.menus, 'id', parseInt(this.$route.query.tab));
              if(menu) {
                this.clickMenu(menu);
                this.filtered.selectedMenu = menu;
              }
            } else {
              this.filtered.selectedMenu = this.menus[0];
            }
          }
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .cat-selected
    position absolute
    left 0
    bottom 0
    width 100%
    background-color $primary
    height 3px

  .cat-wrapper
    display grid
    grid-template-columns repeat(4, 1fr)
    max-width 420px
    margin auto

  .cat-st
    flex 0 0 auto
    position relative
    padding 20px 0
    text-align center

  .product-item-wrapper
    padding-bottom 40px

  .product-item-wrapper:last-child
    border-bottom 0

  .container-wrapper
    min-height 100vh
</style>
